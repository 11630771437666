import React, { Component } from 'react'
import styled from 'styled-components';

import main from 'assets/image/home/home_main.png'
import main_mobile from 'assets/image/home/home_main_mobile.png'
import item1 from 'assets/image/home/home_item_01.png'
import item2 from 'assets/image/home/home_item_02.png'
import item3 from 'assets/image/home/home_item_03.png'
import progressBar1 from 'assets/image/home/progress_bar1.png'
import progressBar2 from 'assets/image/home/progress_bar2.png'
import progressBar3 from 'assets/image/home/progress_bar3.png'
import homeWallet from 'assets/image/home/home_wallet.png'
import homeMall from 'assets/image/home/home_mall.png'
import googlePlay from 'assets/image/google-play.png'
import appStore from 'assets/image/app-store.png'
import { GRAY_COLOR } from '../../config/color';


const ChallengeItems = [
    {
        missionItem: `${item1}`,
        missionName: '7일동안 매일 22000보 걷기',
        leftPedo: '4523보',
        leftMessage: ' 남았습니다.',
        progressBar: `${progressBar1}`
    },
    {
        missionItem: `${item2}`,
        missionName: '7일동안 매일 10000보 걷기',
        leftPedo: '1365보',
        leftMessage: ' 남았습니다.',
        progressBar: `${progressBar2}`
    },
    {
        missionItem: `${item3}`,
        missionName: '7일동안 매일 20000보 걷기',
        leftPedo: '3355보',
        leftMessage: ' 남았습니다.',
        progressBar: `${progressBar3}`
    }
]

const HomeContainerWrap = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
`

const HomeMainContainer = styled.div`
    background-image: url(${main});
    background-repeat: no-repeat;
    position: relative;
    background-size: cover;
    background-position: 50%;
    width: 100%;
    height: 900px;
    padding: 0 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: 768px) {
        background-image: url(${main_mobile});
        background-position: 100%;
        height: 410px;
        align-items: flex-start;
        padding-top: 50px;
    }
`

const HomeMainWidth = styled.div`
    width: 1104px;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const HomeArticleWidth = styled.div`
    width: 1104px;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 768px) {
        width: 100%;
        flex-direction: column;
        justify-content: center;
    }
`

const HomeTitle = styled.h1`
    font-weight: 700;
    font-size: 60px;
    line-height: 1.3125;
    margin-bottom: 62px;
    @media (max-width: 768px) {
        font-size: 30px;
        margin-bottom: 24px;
    }
`

const MissionTitle = styled.h2`
    font-weight: 700;
    font-size: 40px;
    line-height: 1.3125;
    margin-bottom: 25px;

    @media (max-width: 768px) {
        font-size: 20px;
        text-align: center;
        margin-bottom: 10px;
    }
`

const MissionSubTitle = styled.h3`
    /* font-weight: 900; */
    font-size: 20px;
    line-height: 1.3125;
    @media (max-width: 768px) {
        font-size: 12px;
        text-align: center;
    }
`

const AppLinkWrap = styled.div`
    display: flex;
    flex-direction: row;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`

const AppLinkContainer = styled.a`
    display: flex;
    width: 170px;
    height: 50px;
    margin-right: 38px;
    cursor: pointer;
    &:not(:last-child) {
        width: 150px;
    }
    @media (max-width: 768px) {
        width: 130px;
        margin-right: 0px;
        margin-bottom: 10px;
        &:not(:last-child) {
            width: 130px;
        }
        &:not(:first-child) {
            height: 42px;
        }
    }
`

const HomeMissonContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    padding: 0 20px;
    background-color: white;
    @media (max-width: 768px) {
        padding: 40px 0 64px;
        height: auto;
    }
`

const ImageContainer = styled.div`
    width: 120px;
    height: 120px;
    margin-right: 44px;
    @media (max-width: 768px) {
        width: 70px;
        height: 70px;
        margin-right: 24px;
    }
`

const HomeInsureumContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    padding: 0 20px;
    background-color: ${GRAY_COLOR};
    @media (max-width: 768px) {
        padding: 40px 20px 0px;
        height: auto;
    }
`

const HomeMallContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 700px;
    padding: 0 20px;
    background-color: white;
    @media (max-width: 768px) {
        padding: 40px 0px 0px;
        height: auto;
    }
`

const MissionMap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 30px;
    @media (max-width: 768px) {
        margin-top: 26px;
    }
`

const MissionName = styled.span`
    font-weight: 400;
    font-size: 16px;
    line-height: 1.3125;
    @media (max-width: 768px) {
        font-size: 12px;
    }
`

const MissionLeftPedo = styled.span`
    font-weight: 700;
    font-size: 30px;
    line-height: 1.3125;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`

const LeftMessage = styled.span`
    font-weight: 400;
    font-size: 30px;
    line-height: 1.3125;
    @media (max-width: 768px) {
        font-size: 20px;
    }
`

const ProgressBar = styled.div`
    width: 376px;
    height: 4px;
    @media (max-width: 768px) {
        width: 100%;
    }
`

const WalletImage = styled.div`
    background-image: url(${homeWallet});
    background-repeat: no-repeat;
    background-size: 100%;
    background-position-y: 100px;
    width: 400px;
    height: 100%;
    @media (max-width: 768px) {
        width: 184px;
        height: 334px;
        background-position-y: 40px;
    }
`

const MallImage = styled.div`
    background-image: url(${homeMall});
    background-repeat: no-repeat;
    background-size: 100%;
    width: 540px;
    height: 600px;
    @media (max-width: 768px) {
        width: 320px;
        height: 417px;
        margin-top: 20px;
    }
`

const PCMissionTextWrap = styled.div`
    display: block;
    @media (max-width: 768px) {
        display: none;
    }
`

const MobileMissionTextWrap = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: block;
    }
`



export default class Home extends Component{
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }


    render() {
        console.log(ChallengeItems, 'chan')
        return (
            <HomeContainerWrap>
                <HomeMainContainer>
                    <HomeMainWidth>
                        <HomeTitle>
                            걷기만 해도 쌓이는<br/>재테크 앱
                        </HomeTitle>
                        <AppLinkWrap>
                            <AppLinkContainer
                                href="https://apps.apple.com/kr/app/%EB%8D%94%EC%B1%8C%EB%A6%B0%EC%A7%80/id1180618228"
                                alt="더챌린지 ios 링크"
                            >
                                <img style={{width: '100%'}} src={appStore}/>
                            </AppLinkContainer>
                            <AppLinkContainer
                                href="https://play.google.com/store/apps/details?id=com.zikto.thebalance&hl=ko"
                                alt="더챌린지 google play 링크"
                            >
                                <img style={{width: '100%'}} src={googlePlay}/>
                            </AppLinkContainer>
                        </AppLinkWrap>
                    </HomeMainWidth>
                </HomeMainContainer>
                <HomeMissonContainer>
                    <HomeArticleWidth>
                        <PCMissionTextWrap>
                            <MissionTitle>
                                미션을 달성하여<br/>상품을 받으세요.
                            </MissionTitle>
                            <MissionSubTitle>
                                건강해지는 만큼 혜택도 많아 집니다.
                            </MissionSubTitle>
                        </PCMissionTextWrap>
                        <MobileMissionTextWrap>
                            <MissionTitle>
                                미션을 달성하여 상품을 받으세요.
                            </MissionTitle>
                            <MissionSubTitle>
                                건강해지는 만큼 혜택도 많아 집니다.
                            </MissionSubTitle>
                        </MobileMissionTextWrap>
                        <div>
                            {ChallengeItems.map((v, i) => {
                                return (
                                    <MissionMap key={i}>
                                        <ImageContainer>
                                            <img style={{width: '100%'}} src={v.missionItem} />
                                        </ImageContainer>
                                        <div style={{display: 'flex', flexDirection: 'column'}}>
                                            <MissionName>{v.missionName}</MissionName>
                                            <div style={{margin: '5px 0'}}>
                                                <MissionLeftPedo>{v.leftPedo}</MissionLeftPedo>
                                                <LeftMessage>{v.leftMessage}</LeftMessage>
                                            </div>
                                            <ProgressBar>
                                                <img style={{width: '100%'}} src={v.progressBar}/>
                                            </ProgressBar>
                                        </div>
                                    </MissionMap>
                                )
                            })}
                        </div>
                    </HomeArticleWidth>
                </HomeMissonContainer>
                <HomeInsureumContainer>
                    <HomeArticleWidth>
                        <MobileMissionTextWrap>
                            <MissionTitle>
                                인슈어리움 지갑을 통해 더 커진 혜택
                            </MissionTitle>
                            <MissionSubTitle>
                                미션완료 후 인슈어리움 토큰을 획득해보세요.
                            </MissionSubTitle>
                        </MobileMissionTextWrap>
                        <WalletImage/>
                        <PCMissionTextWrap>
                            <MissionTitle>
                                인슈어리움 지갑을 통해 더<br/>커진 혜택
                            </MissionTitle>
                            <MissionSubTitle>
                                미션완료 후 인슈어리움 토큰을 획득해보세요.
                            </MissionSubTitle>
                        </PCMissionTextWrap>
                    </HomeArticleWidth>
                </HomeInsureumContainer>
                <HomeMallContainer>
                    <HomeArticleWidth>
                        <PCMissionTextWrap>
                            <MissionTitle>
                                걷는 만큼 보험금 할인,<br/>걷는 만큼 캐시백
                            </MissionTitle>
                            <MissionSubTitle>
                                교보라이프플래닛, KB카드, 에이스손해보험과<br/>함께합니다.
                            </MissionSubTitle>
                        </PCMissionTextWrap>
                        <MobileMissionTextWrap>
                            <MissionTitle>
                                걷는 만큼 보험금 할인, 걷는 만큼 캐시백
                            </MissionTitle>
                            <MissionSubTitle>
                                교보라이프플래닛, KB카드, 에이스손해보험과 함께합니다.
                            </MissionSubTitle>
                        </MobileMissionTextWrap>
                        <MallImage/>
                    </HomeArticleWidth>
                </HomeMallContainer>
            </HomeContainerWrap>
        )
    }
}