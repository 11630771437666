import React from 'react';
import {Link, withRouter} from 'react-router-dom'
import styled from 'styled-components'
import {POINT_COLOR} from 'config/color'
import logo from "assets/image/logo.png";

const Header = styled.header`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    @media (max-width: 768px) {
        height: 60px;
    }
`;

const AllList = styled.ul`
    justify-content: space-between;
    width: 1104px;
    align-items: center;
    padding: 0 20px;
    display: flex;
    flex-direction: row;
    justify-content: 'space-between';
    @media (max-width: 768px) {
        width: 100%;
    }
`;

const List = styled.ul`
    display: flex;
    flex-direction: row;
    justify-content: 'space-between';
`;

const Item = styled.li`
    width: 70px;
    height: 100px;
    /* &:not(:last-child) {
        margin-right: 10px;
    } */
    text-align: center;
    @media (max-width: 768px) {
        height: 60px;
    }
`;

const SLink = styled(Link)`
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: ${POINT_COLOR};
    font-size: 20px;
    border-bottom: 6px solid ${props => props.current ? `${POINT_COLOR}` : 'transparent'};
    transition: border-bottom .5s ease-in-out;
    padding-top: 30px;
    @media (max-width: 768px) {
        font-size: 12px;
        height: 60px;
        padding-top: 24px;
        border-bottom-width: 2px;
    }
`;

const Image = styled.img`
    height: 30px;
    @media (max-width: 768px) {
        height: 12px;
    }
`;

export default withRouter(({ location : {pathname}}) => {
    console.log(pathname,'-----')
    return (
        <Header>
            <AllList>
                <Item>
                    <SLink to="/kb/" style={{border: 'none', justifyContent: 'flex-start'}}>
                        <Image src={logo} alt="얼마야 로고" />
                    </SLink>
                </Item>
                <List>
                    <Item style={{marginRight: 24}}>
                        <SLink current={pathname === "/kb/"} to="/kb/">
                            홈
                        </SLink>
                    </Item>
                    <Item>
                        <SLink current={pathname === "/kb/event"} to="/kb/event">
                            이벤트
                        </SLink>
                    </Item>
                </List>
            </AllList>
        </Header>
    )
})