import React from 'react';
import Router from 'Components/Router';
import GlobalStyles from 'Components/GlobalStyles';


function App() {
  return (
    <div className="App-default">
      <Router />
      <GlobalStyles />
    </div>
  );
}

export default App;
